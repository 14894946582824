body {
  margin: 0;
  font-family: 'Hellix' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F8F8F8 !important;
}

a {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'CenturyGothic';
  src: url('fonts/centuryGotic/CenturyGothic.eot');
  src: url('fonts/centuryGotic/CenturyGothic.eot?#iefix') format('embedded-opentype'),
  url('fonts/centuryGotic/CenturyGothic.woff2') format('woff2'),
  url('fonts/centuryGotic/CenturyGothic.woff') format('woff'),
  url('fonts/centuryGotic/CenturyGothic.ttf') format('truetype'),
  url('fonts/centuryGotic/CenturyGothic.svg#CenturyGothic') format('svg');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('fonts/centuryGotic/GOTHICB0.TTF') format('truetype');
  font-weight: bold;
  font-display: swap;
}