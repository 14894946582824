.carousel .thumb {
    transition: border .15s ease-in;
    display: inline-block;
    margin-right: -3px;
    white-space: nowrap;
    overflow: hidden;
    border: 0px;
    padding: 1px;
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 0px !important;
}

ul.thumbs {
    margin: 0 !important;
    padding: 0 !important;
}

.carousel .thumbs-wrapper {
    margin: 0 !important;
}

.carousel {
    margin-top: 0.7rem;
}

/***SWIPER SLIDER***/
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.swiper-pagination-bullet {
    width: 36px;
    height: 3px;
    background-color: #33383C;
    border-radius: 0;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 !important;
}
