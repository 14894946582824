.CategoriesTitle {
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 32px 2px;
    flex-shrink: 0;
    flex-wrap: wrap;
}

.CategoriesTitle h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    font-family: "Atacama VAR";
}

.TopCategoryContainer {
    display: flex;
    width: 100%;
    height: 333px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    flex-shrink: 0;
}

.TopCategory {
    height: 100% !important;
    width: 100% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position-y: 10% !important;
    background-position-x: center !important;
}

.BottomCategoryContainer {
    display: flex;
    width: 100%;
    height: 333px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px;
    flex-shrink: 0;
}

.BottomCategory1, .BottomCategory2, .BottomCategory3 {
    height: 100% !important;
    width: 50% !important;
    background-repeat: no-repeat !important;
    background-position-x: center !important;
}

.BottomCategory2 {
    background-position-y: 18% !important;
}

.BottomCategory1, .BottomCategory3 {
    background-position-y: 50% !important;
}

.CategoryNameContainer {
    text-transform: uppercase;
    padding: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    color: #000 !important;
}

.TopCategoriesContainer {
    display: flex;
    width: 100%;
    height: 333px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px;
    flex-shrink: 0;
}

.TopCategory1, .TopCategory2, .TopCategory3 {
    height: 100% !important;
    width: 33.33% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position-x: center !important;
}

.TopCategory1 {
    background-position-y: 8% !important;
}

.TopCategory2 {
    background-position-y: 80% !important;
}

.MultipleCategories .TopCategoriesContainer,
.MultipleCategories .BottomCategoryContainer {
    height: 466px;
}

.MultipleCategories .BottomCategory1,
.MultipleCategories .BottomCategory2,
.MultipleCategories .BottomCategory3 {
    width: 33.33% !important;
}

.MobileCategories .MiddleCategoriesContainer {
    display: flex;
    width: 100%;
    height: 333px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.MiddleCategory1, .MiddleCategory2 {
    height: 100% !important;
    width: 50% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position-x: center !important;
}

.MobileCategories .TopCategory1,
.MobileCategories .TopCategory2 {
    width: 50% !important;
}

.MobileCategories .BottomCategory1,
.MobileCategories .BottomCategory2 {
    width: 50% !important;
}

.MultipleCategories .TopCategoriesContainer,
.MultipleCategories .BottomCategoryContainer,
.MobileCategories .MiddleCategoriesContainer{
    gap: 3px !important;
}

.MobileCategories .TopCategoriesContainer,
.MobileCategories .BottomCategoryContainer {
    height: 250px;
}

.MultipleCategories {
    gap: 3px !important;
    flex-direction: column;
}

.CategoriesContainer.MobileCategories.MultipleCategories {
    margin-bottom: 63px;
}

@media screen and (max-width: 640px) {
    .BottomCategoryContainer {
        height: 250px;
    }

    .TopCategoriesContainer a,
    .MiddleCategoriesContainer a,
    .BottomCategoryContainer a{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
    }

    .MobileCategories .MiddleCategoriesContainer {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .CategoryNameContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -54px
    }
}


@media (max-width: 413px) {
    .CategoryNameContainer.Skincare {
        margin-bottom: -68px;
    }
}