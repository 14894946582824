.GifteesContainer {
    min-height: 15rem;
    height:100%;
}

.GifteesContainer {
    overflow-x: hidden;
}

.GifteeNameContainer h4{
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.GifteePhoneContainer, .GifteeEmailContainer {
    font-size: 13px;
    font-style: normal;
    line-height: 20px;
}

.NoGifteesContainer {
    display: flex;
    padding: 32px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0;
    align-self: stretch;
    height: 15rem!important;
}

.NoGifteesContainer h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.NoGifteesContainer p{
    font-size: 13px;
}

.NoGifteesContainer img {
    width: 100%;
}

.GifteeMoreContainer , .GifteeItemsContainer {
    display: flex;
    justify-content: flex-end;
}

.GifteeItemsContainer, .GifteeEventDateContainer {
    color: #757575;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 181.818% */
}

.GifteeNameContainer {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.GifteeOwnerContainer {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.GifteeMenuItem a, .GifteeMenuItem span {
    color: #000;
    font-family: 'Hellix';
    text-decoration: none !important;
}

.GifteeMenuItem svg {
    max-width: 20px;
}

.tooltip-inner {
    background-color: #FFFFFF !important;
}

.GifteeMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 700;
    font-size: 12px;
    padding: 8px;
    gap: 5px;
}

.GifteeTermsAcceptedContainer, .GifteePrivacyAcceptedContainer {
    color: var(--Dark-Grey, #757575);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 172.727% */
}

.GifteeContainer {
    padding: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    border-top: 1px solid #E5E5E5;
}

.GifteeMoreContainer a {
    color: #000 !important;
    font-size: 13px;
}

.GifteeContainer img {
    margin-right: 6px;
}

.GifteeMoreContainer img {
    margin-top: -3px;
    margin-left: 5px;
}