.ProductContainer {
    margin-top: 16px;
    display: flex;
    padding: 0px 20px;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    margin-bottom: 32px;
    height: calc(100vh - 60px);
}

.ProductDescriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 992px) {
    .ProductContainer {
        flex-direction: column !important;
        gap: 16px !important;
        height: auto !important;
        margin-bottom: 60px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .ProductContainer.row.no-gutter {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media (max-width: 768px) {
    .ProductDescription {
        padding: 0px !important;
    }
}

@media (min-width: 768px) {

    .ProductPageContainer {
        max-height: calc(100vh - 5px) !important;
    }

    .ProductContainer {
        display: flex;
        align-items: flex-start;
    }

    .ProductDescriptionContainer {
        align-items: center;
        justify-content: center;
        height: max-content;
        margin-top: 30px !important;
    }

    .ProductImageContainer {
        height: 100% !important;
    }

    .ProductDescription {
        max-height: 250px;
    }

    .no-gutter {
        padding: 0px !important;
        margin-left: -25px !important;
        margin-right: -25px !important;
    }

    .ProductInfosContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

}

.ProductTitle {
    color: #33383C;
    font-size: 18px;
    font-family: "Atacama VAR";
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ProductSubtitle {
    color: #7B8487;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ProductCode {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #7B8487;
}

.ProductDescription {
    color: #757575;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    flex-shrink: 0;
    overflow: auto;
    padding: 8px;
}

.ProductPrice {
    color:  #33383C;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.ProductInfoContainer.row {
    width: 100%;
}

.ProductPrice.col-6 {
    text-align: end;
}

.ProductImageContainer {
    height: auto;
}

ProductVariationSelect label, .ProductVariationSelect div {
    font-family: 'Hellix' !important;
    font-size: 13px !important;
    width: 100%;
}

.ProductVariationSelect {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProductVariations {
    font-family: 'CenturyGothic' !important;
    font-size: 13px !important;
}

.ProductVariationSelect #variation-select{
    padding: 10px;
}

.ProductInfosColumn {
    overflow-y: auto;
}