
.HomePageHeader{
    display: flex;
    height: 234px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.HomeUserName {
    color: var(--black, #000);
    font-family: 'Hellix'!important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
}

.HomePageTabContent {
    height: 100% !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.HomePageTabs .nav.nav-tabs {
    border-bottom: 0px !important;
}

.HomePageTabs .nav .nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px !important;
    flex: 1 1 0;
    align-self: stretch;
}

.HomePageTabs .nav .nav-item button {
    text-decoration: none !important;
    border: 0 !important;
    color: var(--black, #000) !important;
    font-size: 13px;
}

.HomePageTabs .nav-tabs .nav-item.show .nav-link, .HomePageTabs .nav-tabs .nav-link.active {
    color: var(--black, #000) !important;
    width: 100% !important;
    height: 100% !important;
    font-weight: 700;
}

.HomePageTabs .nav-item {
    border-bottom: #e5e5e5 solid 1px !important;
}

.HomePageTabs .nav-tabs .nav-link.active {
    border-bottom: black solid 2px !important;
}
