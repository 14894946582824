.containerDiorLogo{
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-bottom: 1px solid #E5E5E5;
    background: #F8F8F8;
}

.containerLinkBack {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.linkBack{
    color: var(--black, #000)!important;
    font-family: 'Hellix'!important;
    text-transform: uppercase;
    font-size: 13px!important;
    line-height: 20px!important;
    font-weight: 700!important;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.containerArrowBack{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 8px;
}


.HeaderActions {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.LogoDior {
    width: auto;
    position: absolute;
    height: 60px;
    padding-top: 13.5px;
    padding-bottom: 13.5px;
}

.containerLinkBack span {
    font-weight: 700;
}

/*language selector*/
#menu- li {
    font-size: 12px;
    color: #7B8487;
    font-family: 'Hellix' !important;
}