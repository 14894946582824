.CreateMessageForm{
    display: flex;
    padding: 36px 0px 64px 0px;
    flex-direction: column;
    gap: 36px;
}

.CreateMessageTitle h4 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
}

form.CreateMessageForm label {
    font-family: 'Hellix';
}

.CreateMessageForm {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 0px 20px;
}

.AcceptTermsContainer span, .AcceptTermsContainer a,
.AcceptPrivacyContainer span, .AcceptPrivacyContainer a {
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #000 !important;
}

.AcceptPrivacyContainer a, .AcceptTermsContainer a {
    text-decoration: underline !important;
}