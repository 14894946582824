.FiltersContainer, .SortContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.FiltersContainer {
    height: 120%;
}

.FiltersContainer .MuiAccordion-rounded, .SortContainer .MuiAccordion-rounded {
    /* border: 0px !important; */
    box-shadow: none !important;
    border-bottom: 0px !important;
    background-color: #F8F8F8 !important;
    border-radius: 0px !important;
    position: static;
}

.Filters, .SortBy {
    text-align: left;
}

.OpenFiltersContainer {
    text-align: right;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.OpenFiltersContainer span, .OpenSortContainer span {
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.SearchBarContainer form{
    padding:0px 20px;
}

.SearchBarBottom {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
}

.SearchBarBottom span {
    color: var(--Dark-Grey, #757575);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}

.Sort p {
    cursor: pointer;
}

.ResetFiltersContainer {
    color: #000;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-decoration-line: underline;
    position: fixed;
    bottom: 74px;
    width: calc(100% - 30px);
    margin: auto;
    background-color: transparent;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ResetFiltersContainer span {
    cursor: pointer;
}


.Filter, .Sort {
    font-size: 13px;
}

.fgift-custom-dialog {
    max-width: 900px !important;
}

.Filter p{
    cursor: pointer;
}

.FiltersContainer p, .SortContainer p {
    font-weight: 400;
    color: #33383C;
    font-size: 14px;
}

.SearchBarBottom span {
    text-transform: capitalize;
}

.DesktopSearchBar {
    margin-top:-13px;
    max-width: 150px;
}

.DesktopSearchBar label, .DesktopSearchBar input {
    font-size: 13px;
    font-style: normal;
    color: #000;
}

@media (min-width: 992px) {
    .WishlistItemsContainer .SearchBarBottom, .CatalogueContainer .SearchBarBottom {
        margin-bottom: -32px;
    }

    .WishlistItemsContainer .SearchBarBottom {
        padding: 0px !important;
        margin-left: -25px !important;
        margin-right: -25px !important;
    }
}

.filter-sort-modal .modal-body {
    min-height: 120% !important;
}


.modal-header {
    padding: 20px 28px 20px 31px !important;
}

@media (max-width: 992px) {
    .filter-sort-modal .modal-content {
        overflow-y: auto !important;
    }

    .ResetFiltersContainer {
        bottom: 63px;
    }

    .modal-header {
        padding: 20px 20px 20px 20px !important;
    }
}



.SortContainer {
    padding-left: 20px;
}

.SortContainer p {
    color: #33383C;
}

.SortContainer p:first-child {
    margin-bottom: 2rem;
}

.FiltersContainer .Mui-expanded .MuiAccordionSummary-content p {
    font-weight: 700 !important;
}