.WishlistForm, .WishlistShippingFormContainer {
    display: flex;
    padding: 36px 20px 64px 20px;
    flex-direction: column;
    gap: 36px;
}

.WishlistTitle h4 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
}

form.WishlistForm label,
.WishlistShippingAccordionContainer input,
.WishlistShippingAccordionContainer label,
.WishlistShippingAccordionContainer p,
.WishlistShippingAccordionContainer span{
    font-family: 'Hellix' !important;
}

.WishlistPickupAddressContainer{
    padding-left: 36px;
}

.WishlistShippingAccordionContainer .MuiAccordion-rounded {
    /* border: 0px !important; */
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    border-radius: 0px !important;
}

.WishlistSelectShippingContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.87);
    padding-top: 20px;
}

div#panel1a-header {
    padding: 0;
}

#panel1a-content div:first-child {
    padding-right: 0 !important;
    padding-left: 0 !important;
    display: flex;
    flex-direction: column;
}

input[type=radio] {
    accent-color: #000;
}

.SelectClient label {
    margin-left: -15px;
}

.WishlistNameField label{
    font-weight: 700;
    color: #000;
}
