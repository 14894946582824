.PublicRegistrationForm {
    padding: 36px 20px 28px 20px !important;
}

.PublicRegistrationTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 112%;
    background-position-y: 19%;
}

.PublicRegistrationTitle h3 {
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0 !important;
}

.AcceptTermsContainer span, .AcceptTermsContainer a, .AcceptPrivacyContainer span, .AcceptPrivacyContainer a, .AcceptMarketingContainer span {
    padding: 0px !important;
}

.AcceptTermsContainer {
    margin-top: 36px;
}

.AcceptTermsContainer, .AcceptPrivacyContainer, .AcceptMarketingContainer {
    margin-bottom: 16px;
}

.AcceptTermsContainer, .AcceptPrivacyContainer, .AcceptMarketingContainer {
    text-wrap: wrap !important;
    display: flex;
    flex-direction: row;
    align-content: start !important;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.SubTextContainer {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: #757575;
    margin-bottom: 64px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 992px) {
    .PublicRegistrationForm {
        padding: 36px 10px 28px 20px !important;
    }

    .PublicRegistrationForm .MuiFormHelperText-root {
        font-size: 10px;
    }
}