.WishlistsContainer {
    min-height: 15rem;
}

.WishlistContainer {
    overflow-x: hidden;
    gap: 16px;
    border-top: 1px solid var(--Light-Grey, #E5E5E5);
    display: flex;
    padding: 32px 20px;
    flex-direction: column;
    justify-content: center;
}

.WishlistId, .WishlistGiftee, .WishlistCreatedBy{
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 1px;
}

.NoWishlistContainer {
    display: flex;
    padding: 32px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    height: 15rem!important;
}

.NoWishlistContainer img {
    width: 100%;
}

.WishlistMoreContainer , .WishlistEventDateContainer {
    display: flex;
    justify-content: flex-end;
}

.WishlistNameContainer h4{
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.WishlistItemsContainer, .WishlistEventDateContainer {
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 181.818% */
}

.WishlistEventDateContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 1px !important;
}

.WishlistNameContainer {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.WishlistOwnerContainer {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.WishlistMenuItem a, .WishlistMenuItem span {
    color: #000;
    font-family: 'Hellix';
    text-decoration: none !important;
}

.WishlistMenuItem svg {
    max-width: 20px;
}

.tooltip-inner {
    background-color: #FFFFFF !important;
}

.WishlistMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 700;
    font-size: 12px;
    padding: 8px;
    gap: 5px;
}

.SearchBarContainer {
    display: flex;
    padding: 32px 0px;
    flex-direction: column;
    gap: 24px;
    align-self: stretch;
}

.NoWishlistContainer h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
}

.NoWishlistContainer p {
    font-size: 13px;
    font-style: normal;
}

.WishlistGiftee {
    line-height: 1px !important;
}

.WishlistItemsContainer {
    line-height: 1px !important;
    margin-bottom: 90px;
}

.WishlistMoreContainer a {
    color: #000 !important;
    font-size: 13px;
}

.WishlistContainer img {
    margin-right: 6px;
}

.WishlistMoreContainer img {
    margin-top: -3px;
    margin-left: 5px;
}