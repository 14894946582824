.MessageContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-self: stretch;
    border-radius: 4px;
    background: #ffffff;
    margin: 20px;
    border: 1px solid var(--Light-Grey, #E5E5E5);
    padding: 24px;
    align-items: center;
    gap: 20px;
}

.MessageTitle {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 153.846% */
    text-transform: uppercase;
}

.MessageText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
}

.MessageTextLogo img {
    max-width: 55px;
}

.MessageHeader {
    display: flex;
    justify-content: flex-start;
    column-gap: 32px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid var(--Light-Grey, #E5E5E5);
}

.MessageImage {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.MessageImage img {
    width: 117px;
    border-radius: 4px;
}

.MessageProductTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px !important;
    font-family: 'Hellix' !important;
}

p.MessageProductSubtitle {
    font-size: 12px;
    color: #7B8487;
}

.MessageProductTitle h3{
    font-size: 13px !important;
}

.row.MessageSender {
    word-break: break-word;
    color: #7B8487;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    width: 100%;
}