.GifteeForm{
    display: flex;
    padding: 36px 20px 64px 20px;
    flex-direction: column;
    gap: 36px;
    margin-bottom: 30px;
}

.GifteeTitle h4 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
}

form.GifteeForm label {
    font-family: 'Hellix';
}


#civility-select-label {
    margin-right: -15px;
}

#locale-select-label, #boutique-select-label {
    margin-left: -15px;
    margin-top: 10px;
}