.PageNotFoundContainer {
    height: 100vh;
}

.PageNotFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
}

.PageNotFound h1 {
    font-size: 16px;
    font-weight: 700;
}

.PageNotFound p {
    font-size: 13px;
}