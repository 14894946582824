.WishlistHeader {
  display: flex;
  height: 190px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  flex-shrink: 0;
  background-position: center;
  background-size: inherit;
  border-bottom: 1px solid #E5E5E5;
}

.WishlistDateContainer {
  font-size: 13px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.ShareText {
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
}

.WishlistSocialShareContainer {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 16px;
}

.WishlistSocialShareButton {
  border-radius: 7px;
  background: #33383C;
  display: flex;
  height: 30px;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.ButtonContainer{
  margin-top: 20px;
}

.WishlistSocialShareIcons {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 14px;
  margin-bottom: 15px;
}

.WishlistDesktopDates {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  line-height: normal;
  gap: 5px;
  padding: 48px;
}

.modal-dialog {
  position: fixed !important;
  min-width: 95% !important;
  bottom: 0;
  height: 50%;
}

.modal-content {
  height: auto !important;
  min-height: 100% !important;
  min-width: 100% !important;
  border-radius: 0;
  background-color: #F8F8F8 !important;
}

.modal-title {
  font-size: 13px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px; /* 153.846% */
  text-transform: uppercase;
}

.modal-body {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center !important;
  padding: 52px 1px 154px 5px !important;
  gap: 24px !important;
  text-align: center;
}

@media (max-width: 768px) {

  .FiltersContainer .MuiAccordion-rounded:last-of-type {
    margin-bottom: 120px !important;
  }

  .WishlistHeader {
    background-size: 200% !important;
    background-position: 68% 43% !important;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }

  .WishlistDateContainer p {
    font-size: 10px;
  }

  .WishlistPriceDisclaimer p {
    margin-bottom: 2.5rem;
  }

  .fade.right.modal.show {
    background-image: url('/src/media/images/Background_filter.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .modal-content {
    max-height: 90%;
  }

}

@media (min-width: 768px) {

  .modal-body {
    justify-content: center;
  }

  .modal-dialog {
    left: 50%;
    transform: translate(-50%) !important;
    min-width: 50% !important;
  }
}


.NoProductsText {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.NoProducts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 320px;
  flex: 1 0 0;
  align-self: stretch;
}

.DatesContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.WishlistDates {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--Image-grey, #F2F2F4);
}

.WishlistDateContainer p {
  margin: 0 !important;
}

.WishlistHeader .HomeUserName {
  text-align: center;
  font-weight: 400;
}

.WishlistTitleContainer {
  text-align: center;
  font-family: "Atacama VAR";
}

.ShareContainer {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.WishlistText {
  display: flex;
  padding: 0px 7px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 32px;
  color: #33383C;
}

.WishlistGiftee p {
  line-height: normal;
}

.NoProductsSubtitle {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0px 20px;
}

.ShareIcons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WishlistPriceDisclaimer {
  color: #757575;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal-fullscreen {
    height: 100% !important;
    width: 100vw;
    min-width: 100vw !important;
  }

  .WishlistHeader {
    height: 300px;
    background-size: 85% !important;
    background-position-y: 54% !important;
    background-position-x: 33% !important;
  }

  /*.ShareModal .modal-dialog {
    height: 100% !important;
    min-width: 100vw !important;
    margin-bottom: -20px;
  }*/

  .filter-sort-modal .modal-body {
    max-height: 100% !important;
  }

  .SortButton {
    position: relative;
  }

  .FilterButton {
    margin-top: 30px;
  }

}

.react-share__ShareButton {
  width: 100%;
}

.WishlistSocialShareIcons span{
  font-size: 14px;
  color: #33383C !important;
}

button.btn-close {
  background-size: 15px 10px;
}

.WishlistItemsContainer span.CancelItemContainer {
  padding-bottom: 11px !important;
}

