.WelcomeTitle {
    font-weight: 400;
    font-family: "Atacama VAR";
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    color: #33383C;
}

.Facebook-access {
    display: none !important;
}

.LoginSignupAccordionSummary h3, .HowItWorksTitle h4 {
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 7px;
    white-space: nowrap;
    text-transform: uppercase;
}

.LoginSignupAccordionSummary p {
    font-size: 0.77rem; /* 13px / 16 = 0.8125rem, 0.8125rem * (13 / 1680) = 0.00774rem * 100 = 0.77rem */
    font-style: normal;
    font-weight: 400;
}

.LoginSignupAccordionSummary .MuiAccordionSummary-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.LoginSignupImage {
    width: 100%;
    height: 10.13rem; /* 170px / 16 = 10.625rem, 10.625rem * (170 / 1680) = 0.1013rem * 100 = 10.13rem */
    overflow: hidden;
    position: relative;
}

.LoginSignupImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LoginSignupParagraph {
    margin-top: 30px;
    font-size: 0.95rem; /* 16px / 16 = 1rem, 1rem * (16 / 1680) = 0.00952rem * 100 = 0.95rem */
    font-weight: 700;
    line-height: 1.19rem; /* 20px / 16 = 1.25rem, 1.25rem * (20 / 1680) = 0.0119rem * 100 = 1.19rem */
    text-align: center;
}

.LoginSignupContainer .MuiAccordion-root {
    border-bottom: 1px solid #E5E5E5 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.grecaptcha-badge {
    z-index: 999999 !important;
}

.LoginSignupContainer label {
    font-size: 0.95rem; /* 16px / 16 = 1rem, 1rem * (16 / 1680) = 0.00952rem * 100 = 0.95rem */
}

.LoginSignupContainer input[value] {
    font-size: 0.95rem; /* 16px / 16 = 1rem, 1rem * (16 / 1680) = 0.00952rem * 100 = 0.95rem */
}

.LoginSignupContainer .MuiFormHelperText-root {
    font-size: 0.71rem; /* 12px / 16 = 0.75rem, 0.75rem * (12 / 1680) = 0.00714rem * 100 = 0.71rem */
}

.LoginSignupContainer .AcceptTermsContainer span,
.LoginSignupContainer .AcceptMarketingContainer span,
.LoginSignupContainer .AcceptTermsContainer a,
.LoginSignupContainer .AcceptMarketingContainer a{
    font-size: 0.77rem !important; /* 13px / 16 = 0.8125rem, 0.8125rem * (13 / 1680) = 0.00774rem * 100 = 0.77rem */
}

.LoginSignupContainer .Fgift-button,
.LoginSignupContainer .ResetPasswordLink{
    font-size: 0.77rem !important; /* 13px / 16 = 0.8125rem, 0.8125rem * (13 / 1680) = 0.00774rem * 100 = 0.77rem */
}

.fb-text {
    width: 100% !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 7px;
    font-size: 0.77rem; /* 13px / 16 = 0.8125rem, 0.8125rem * (13 / 1680) = 0.00774rem * 100 = 0.77rem */
    font-weight: 400;
}

.fb-text span {
    margin-left: 46%;
    transform: translateX(-50%);
    text-wrap: nowrap;
}

.LoginSignupContainer .Fgift-button {
    height: 3.25rem; /* 52px / 16 = 3.25rem, 3.25rem * (52 / 1680) = 0.0325rem * 100 = 3.25rem */
}

.Facebook-access, .Google-button {
    padding-left: 16px;
    border-radius: 1000px;
}

@media (min-width: 992px) {
    .grecaptcha-badge {
        bottom: 30% !important;
    }

    .CustomDivider {
        text-align: center;
        margin: auto !important;
        margin-left: 50% !important;
        transform: translateX(-50%);
    }

    .CustomDivider::after, .CustomDivider::before {
        max-width: 100px;
        border-top: thin solid rgba(0, 0, 0, 0.12);
    }
}

@media (max-width: 992px) {
    .LoginSignupImage {
        height: 100%;
    }

    .fb-text {
        font-size: 13px;
    }

    .LoginSignupContainer .Fgift-button,
    .LoginSignupContainer .ResetPasswordLink {
        font-size: 13px !important;
    }

    .LoginSignupContainer .Fgift-button {
        height: 52px;
    }

    .LoginSignupParagraph {
        font-size: 13px;
        line-height: 20px;
    }

    .LoginSignupAccordionSummary h3, .HowItWorksTitle h4 {
        font-size: 13px;
    }

    .LoginSignupAccordionSummary p {
        font-size: 13px;
    }

    .LoginSignupParagraph {
        font-size: 16px;
    }

    .LoginSignupContainer label {
        font-size: 16px;
    }

    .LoginSignupContainer input[value] {
        font-size: 16px;
    }

    .LoginSignupContainer .MuiFormHelperText-root {
        font-size: 12px;
    }

    .LoginSignupContainer .AcceptTermsContainer span,
    .LoginSignupContainer .AcceptMarketingContainer span,
    .LoginSignupContainer .AcceptTermsContainer a,
    .LoginSignupContainer .AcceptMarketingContainer a {
        font-size: 13px !important;
    }
}

div#filters-panel1a-header {
    background-color: #f8f8f8;
}

.MuiCollapse-wrapperInner.MuiCollapse-vertical {
    background-color: #f8f8f8;
}

.LoginSignupContainer input[value] {
    font-family: 'Hellix';
}

.CustomDivider {
    font-family: 'Atacama VAR';
}
