.ProductGrid, noProducts {
    margin-top: 32px !important;
}

.ProductPreview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    height: 390px;
}

@media (min-width: 460px) {
    .ProductPreview {
        height: 420px;
    }
}

@media screen and (min-width: 615px) and (max-width: 991px) {
    .ProductGrid.no-gutter {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

.ProductPreviewName {
    color: #33383C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ProductPreviewDescription {
    color: #7B8487;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    height: 33px;
}

.ProductPreviewPrice {
    color: #33383C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.ProductGridItem {
    margin-bottom: 32px;
}

.ProductPreviewInfo {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.ProductPreviewImageContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    position: relative;
    justify-content: flex-start;
    max-width: 99%;
}

.ProductPreviewImageContainer a{
    height: 100%;
    width: 100%;
    background-color: #f2f2f4;
}

.SendMessageContainer {
    position: absolute;
    max-width: 200px;
    margin-right: 8px;
}

.Purchased {
    position: absolute;
    color: #7B8487;
    padding: 10px;
    font-family: 'Hellix';
    font-size: 13px;
    top: 12px;
    left: 15px;
}

.PurchasedItemContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    margin-top: 8px;
}

.GifterNameContainer {
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #33383C;
}

.GiterMessageLinkContainer {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.GiterMessageLinkContainer a {
    color: #000 !important;
}

.CancelItemContainer, .GifterMessageLink {
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    color: #7B8487;
    font-weight: 400;
    border-bottom: 1px solid #7B8487 !important;
    margin-top: 1.2rem;
    padding-bottom: 3px !important;
}

.ModalDeleteItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    gap: 16px;
}

.ModalDeleteItemContainer p{
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 500;
}

.ConfirmDelete, .CancelDelete {
    width: calc(100% - 40px);
    justify-content: center;
    display: flex;
    align-items: center;
}

.CancelDelete {
    text-decoration: underline;
}

.ConfirmDelete button, .CancelDelete button{
    width: 100%;
}

.ItemsPerPageContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.ProductOtherInfoContainer {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.ProductPreviewImage {
    max-height: 300px;
}

@media (max-width: 768px) {
    .fade.modal.show {
        background-image: url('/src/media/images/Background_filter.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .modal-content {
        max-height: 90%;
    }
}