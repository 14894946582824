.FooterDior {
    padding: 48px 20px 48px 20px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.FooterSection {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 16px;
}

.FooterDior h4 {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
}

.FooterDior a, .FooterDior p {
    color: #7B8487;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.FooterDisclaimer {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #E5E5E5;
}

.FooterDisclaimer p {
    color: #757575;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    align-items: flex-start;
    height: 58px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .FooterDisclaimer p {
        height: 104px;
        padding-top: 16px;
    }

    .FooterDior .LanguageContainer {
        margin-bottom: 120px;
        width: 100% !important;
    }

    .FooterDior div#language-select {
        width: 100% !important;
        color: #33383C !important;
        font-size: 14px !important;
    }

    .FooterDior .MuiFormControl-root {
        width: 100% !important;
    }
}
