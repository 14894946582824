.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.DiorContainer {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-height: 812px) {
  .DiorContainer {
    overflow-y: auto;
  }
}


@media screen and (max-width: 768px) {
  .no-sm-gutter {
    padding: 0px !important;
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
}

@media screen and (max-width: 992px) {
  .DiorContainer {
    overflow: auto;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a > h4 {
  color: var(--black) !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

:root {
  --black: #000;
  --white: #FFF;
  --light-grey: #E5E5E5;
  --dark-grey: #757575;
  --light-blue: #61DAFB;
  --dark-blue: #282c34;
  --max-width: 820px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.Fgift-container {
  max-width: var(--max-width);
  display: flex !important;
  flex-direction: column !important;
}

.Fgift-centered-layout {
  max-width: var(--max-width);
}

.Fgift-inputContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 0 !important;
  border: 0px !important;
  border-bottom: 1px solid !important;
}

.Fgift-label {
  color: var(--dark-grey, #757575);
  font-family: 'Atacama VAR'!important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.Fgift-button-reverted {
  color: var(--black, #000) !important;
  background: var(--white, #FFF) !important;
  border: 1px solid #fff !important;
}

.Fgift-button {
  display: flex;
  height: 52px;
  line-height: normal;
  text-align: center;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border: 0px;
  border-radius: 7px;
  background: #33383C;
  color: var(--white, #FFF);
  text-decoration: none !important;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  max-width: 450px;
}

.Fgift-button[disabled] {
  background:  #B3B3B3;
  border: 1px solid #B3B3B3;
}

div#language-select {
  font-family: 'Hellix';
  font-size: 12px;
  color: #7B8487 !important;
}

.ModalCookieContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 0 !important;
}

.ConfirmCookies, .CancelCookies {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ModalCookie .modal-dialog {
  height: 80% !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.LoaderContainer {
  height: 100% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}


.LoaderContainer img {
  width: 100px;
}

.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  width: calc(100% - 40px);
  align-items: center;
  position: fixed;
  bottom: 20px;
  margin: auto;
  max-width: 930px;
  left: 0;
  right: 0;
}

@media screen and (max-width: 640px) {
  .ButtonContainer {
    max-width: -webkit-fill-available;
  }

  .LoaderContainer {
    position: relative !important;
  }

  .LoaderContainer img {
    width: 44px;
    height: 24px;
  }
}


@media (min-width: 992px) {
  .position-lg-relative {
    position: relative !important;
  }
}

/***MUI edits**/
.FgiftCustomCollapse.MuiCollapse-root {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10000 !important;
  max-width: 915px;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #000 !important;
}

.MuiDialogActions-root .MuiButton-textSizeMedium {
  color: #000;
}

.Mui-focused {
  color: #000 !important;
}

.MuiInputBase-root::after {
  border-bottom: 1px solid #000 !important;
}
/**product variation select**/
.MuiOutlinedInput-notchedOutline:not(.Mui-disabled) {
  border-color: #000 !important;
}


/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR ONLY FOR DESKTOP
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
@media (min-width: 992px) {
  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    position: fixed !important;
    margin: auto !important;
    width: 320px !important;
    height: 100% !important;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .modal.left .modal-content,
  .modal.right .modal-content {
    height: 100% !important;
    overflow-y: auto !important;
  }

  .modal.left .modal-body,
  .modal.right .modal-body {
    padding: 15px 15px 80px !important;
  }

  /*Left*/
  .modal.left.fade .modal-dialog{
    left: -320px !important;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
  }

  .modal.left.fade.in .modal-dialog{
    left: 0;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: -50% !important;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    transition: opacity 0.3s linear, right 0.3s ease-out !important;
  }

  .modal.right.fade.in .modal-dialog {
    right: 0 !important;
  }

}

.MuiAlert-message, .MuiFormHelperText-root {
  font-family: 'Hellix' !important;
}
