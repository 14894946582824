.HowToBuyContainer h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
}

.HowToBuy p {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left !important;
    font-family: 'Hellix' !important;
    color: #7B8487;
}

.WishlistAddress {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 7px;
    align-items: center;
    margin-top: -8px;
}


.HowToBuyContainer {
    display: flex;
    padding: 18px 0px 18px 0px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    background-color: #F2F2F4;
}

.HowToBuySectionTitle {
    font-size: 16px !important;
    font-style: normal;
    color: #33383C !important;
    line-height: 20px;
}

.HowToBuy .Mui-expanded {
    margin: 0px !important;
}
.HowToBuy {
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: column;
}

.HowToBuy .MuiAccordion-rounded {
    border-radius: 0px;
}

.HowToBuy .AddressName {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
}

.HowToBuy .AddressName p{
    font-size: 21px;
    font-style: normal;
    margin: 0;
    margin-top: 20px;
    font-weight: 400;
    font-family: "Atacama VAR" !important;
    text-align: center;
    color: #33383C !important;
}

.AddressButton, .CallCenterButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowToBuy .MuiAccordionDetails-root {
    background-color: #fff;
}

.WishlistAddress p {
    color: #33383C !important;
    font-size: 16px;
    margin-top: 16px;
}

.HowToBuy .MuiAccordionSummary-content img {
    margin-right: 7px;
}

.HowToBuy .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 5px;
}

.HowToBuy .MuiAccordionSummary-content.Mui-expanded .HowToBuySectionTitle {
    font-weight: 600;
}
