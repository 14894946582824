.LoginForm, .ResetPassword {
    display: flex;
    padding: 36px 20px 64px 20px;
    flex-direction: column;
}

.ResetPassword {
    gap: 36px;
}

.LoginForm .ButtonContainer {
    margin-top: 56px;
    margin-bottom: 6px;
    width: 100% !important;
}

.LoginForm {
    width: 100%;
}

.LoginImage {
    height: 100vh;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .LoginImage {
        height: 30vh;
    }

    .ButtonContainer.GifterLoginButtonContainer {
        width: calc(100% - 40px) !important;
    }
}

.FormContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    justify-content: flex-start;
}

.Fgift-passwordContainer {
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
}

.ShowPasswordIcon {
    width: 13px;
    height: 13px;
}

.LoginSuccessContainer {
    height: 100vh;
}

.LoginSuccess {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 30%;
    padding: 0px 32px;
    text-align: left;
}

.LoginSuccess h1{
    text-transform: uppercase;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.LoginSuccess p{
    text-align: left;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

label{
    font-family: 'Hellix' !important;
}

.FormTitle h4 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
}

.EmailContainer span, .PasswordContainer span {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}

.EmailContainer, .PasswordContainer {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.AcceptTermsContainer, .AcceptPrivacyContainer, .AcceptMarketingContainer {
    text-wrap: nowrap;
}

a.ResetPasswordLink {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline !important;
    color: #757575;
}

span.KeepMeLogged {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.KeepLoggedContainer {
    display: flex;
    padding: 12px 0px;
    align-items: center;
    gap: 4px;
    margin-left: -15px;
}

.PasswordContainer a {
    color: #000;
    text-decoration: underline !important;
}

.AcceptTermsContainer span, .AcceptTermsContainer a,
.AcceptPrivacyContainer span, .AcceptPrivacyContainer a,
.AcceptMarketingContainer span{
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #000 !important;
}

.AcceptPrivacyContainer a, .AcceptTermsContainer a {
    text-decoration: underline !important;
}

#civility-select-label {
    margin-left: -15px;
}

.RegisterLink {
    display: flex;
    justify-content: center;
    align-items: center;
}

.RegisterLink span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    color: #000000 !important;
    margin-right: 5px;
}

.RegisterLink a {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    color: #000000 !important;
    text-decoration: underline !important;
}

@media (min-width: 768px) {
    .LoginContainer {
        max-height: calc(100vh - 5px) !important;
    }
}

.GifterLoginTitle h4 {
    font-size: 22px;
    font-family: 'Atacama VAR';
    text-transform: none !important;
    font-weight: 100 !important;
    color: #33383C;
}

.FormContainer.GifterForm {
    gap: 5px !important;
}

.GifterFormTitle h4, .CreateMessageForm .FormTitle h4{
    color: #33383C;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: none;
    font-family: 'Atacama VAR';
}

