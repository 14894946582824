.PrSuccessContainer {
    height: max-content;
    padding: 80px 40px;
}

.PrSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
}

.PrSuccess h1{
    text-transform: uppercase;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
}

.PrSuccess p{
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 0px !important;
}

@media screen and (max-width: 768px) {
    .PrSuccessContainer {
        height: max-content;
        padding: 80px 40px;
        padding-bottom: 48px;
        padding-top: 48px;
    }
}